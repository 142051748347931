if(WebPageObj.customerPrivacyOptInOptOut) {
    $(document).ready(function(){
        CustomerPrivacyOptInOptOutApp.init();
    });
}

/**
 * Object to handle the app code.
 *
 * @type Object
 */
var CustomerPrivacyOptInOptOutApp = function(){

    var saveOptInOptOutBtn = $("#saveOptInOptOut");
    var feedbackPanelContainer = $(".hiddenFeedbackPanel");

    var generalFailureError = "An unexpected error has occurred. Please try again or submit an inquiry to privacy@avasflowers.com, if the problem persists.";

    return {

        /**
         * Called to init the object
         *
         * @return void
         */
        init: function(){
            var that = this;

            // when the save btn is clicked on
            saveOptInOptOutBtn.on("click", function(e){
                e.preventDefault();
                that.updateOptInOptOut();
            });
        },

        /**
         * Method to make the AJAX call to update the opt in - opt out
         *
         * @return void
         */
        updateOptInOptOut: function(){

            var that = this;

            var waitMsg = "Saving ...";
            var originalBtnMsg = saveOptInOptOutBtn.val();

            // do not proceed, if an existing request is handled.
            if(originalBtnMsg == waitMsg) {
                return;
            }

            // check if an option is Not selected.
            // in theory, this should not never happen because a default option should be seleced by default
            var selectedOption = $("input[name='optInOptOutRadio']:checked");
            if(selectedOption.length == 0) {
                that.displayOOFeedbackPanel('error', "Please select an option: Yes or No.");
                return;
            }

            saveOptInOptOutBtn.val(waitMsg);
            feedbackPanelContainer.hide();

            $.ajax({
                url: "/customer_privacy/update_optin_optout",
                method: "POST",
                data: {
                    status: selectedOption.val()
                },
                dataType: "json",
                success: function (data, textStatus, jqXHR) {

                    if (data.status) {
                        that.displayOOFeedbackPanel('success', data.message);
                    } else {
                        that.displayOOFeedbackPanel('error', data.message);
                    }

                    // add back the original button text
                    saveOptInOptOutBtn.val(originalBtnMsg);
                },
                error: function (jqXHR, textStatus, errorThrown) {

                    that.displayOOFeedbackPanel('error', generalFailureError);

                    // add back the original button text
                    saveOptInOptOutBtn.val(originalBtnMsg);
                }
            });
        },

        /**
         * Display the feedback panel
         *
         * @param string type - The type of the feedback to display: error, success, info
         * @param string message - The error message to display
         *
         * @return void
         */
        displayOOFeedbackPanel: function (type, message) {

            var that = this;

            displayFeedbackPanel(null, feedbackPanelContainer, type, message);
        },

    };

}();