// source:
// system/application/views/special_pages/business_card_promotion.php
// system/application/views/special_pages/feedback_promotion.php
// system/application/views/special_pages/unsubscribe.php


$(function () {
    $('#promocode').on('focus', function () {
        if ($(this).val() == 'Enter Your Promotion Code Here') {
            $(this).val('').attr('class', 'big-input-focus');
        }
    });
    $('#promocode').on('blur', function () {
        if ($(this).val() == '') {
            $(this).val('Enter Your Promotion Code Here').attr('class', 'big-input');
        }
    });
});