/**
 * START: isJSON
 * 
 * @param {string|object} str
 * @returns {bool}
 */
function isJSON(str) {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}
/**
 * END: isJSON
 */

/** START: LOGGER */
/**
 * Leg message placeholder
 * 
 * @type String
 */
var logMessage = '';
/**
 * Leg type placeholder
 * 
 * @type String
 */
var logType = '';

/**
 * StackTrace extender, post the errors to the file
 * 
 * @param {array} stackframes
 * @returns {void}
 */
var logPost = function (stackframes) {
    $.post('/api/logger/js',
            {
                log: logMessage,
                type: logType,
                stack: JSON.stringify(stackframes)
            },
            function (data) {},
            "json");
};
/**
 * START: Log Error
 * 
 * @param {object} error - containing error name and message
 * @param {bool} explicit - if the error is explicity
 * @returns {void}
 */
function logError(error, explicit) {
    logMessage = '[' + (explicit ? 'EXPLICIT' : 'INEXPLICIT') + '] ' + error.name + ': ' + error.message;
    logType = (explicit ? 'warn' : 'error');
    StackTrace.fromError(error).then(logPost);
}
/**
 * END: logJsError
 */

/**
 * START: Append arrow to the subnavigations
 */
function appendArrow() {
    $('#nav ul li').has('ul').prepend('<div class="subnavArrow"></div>');
}
/**
 * END: appendArrow
 */

/**
 * START: Filter active status in object and .shop-by-link
 * @type object th
 */
function filterActive(th) {
    if (!th.hasClass('active')) {
        $('.shop-by-link').removeClass('active');
        th.addClass('active');
    } else {
        th.removeClass('active');
    }
}
/**
 * END: filterActive
 */

/**
 * START: validate email
 * 
 * @param {string} email
 * @returns {Boolean}
 */
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
/**
 * END: validateEmail
 */

/**
 * START: Counter plign
 * Set data-target to the textarea you want to limit chars and data-max with
 * the no. of max characters
 */
function charsCounter() {
    $('.charsCounter').each(function () {
        var th = $(this);
        var maxCharsLimit = th.attr('data-max');
        var target = th.attr('data-target');
        th.text(maxCharsLimit);

        // on init when something is filed already in the field
        if ($('#' + target).length) {
            var text = $('#' + target).val();
            var remaining = charsCounterRemaining(text, maxCharsLimit);
            th.text(remaining);
        }

        // on key up, in the message text area
        $('#' + target).on('keyup blur', function (e) {
            var text = $(this).val();
            var remaining = charsCounterRemaining(text, maxCharsLimit);
            if (text.length && remaining === 0) {
                $(this).val(text.substring(0, maxCharsLimit));
            }
            th.text(remaining);
        });

        // on key down, in the message text area
        $('#' + target).on('keydown', function (e) {
            var text = $(this).val();
            var remaining = charsCounterRemaining(text, maxCharsLimit);
            th.text(remaining);
        });
    });
}
/** 
 * END: Counter plign
 */

/**
 * Chars counter remaining limit.
 * 
 * @param {string} text - input value
 * @param {int} maxCharsLimit - max limit
 * @returns {Number} - remaning chars limit
 */
function charsCounterRemaining(text, maxCharsLimit) {
    var remaining = maxCharsLimit - text.length;
    if (remaining < 1) {
        remaining = 0;
    }

    return remaining;
}

/**
 * Moda/Popup generator.
 * 
 * Instead of adding HTML content, we can dynamically create modals / popups.
 * With this approach the HTML content of the site is reduced.
 * 
 * @param {string} id - identificator for HTML tag
 * @param {string} title - string to show in h4 title tag
 * @param {string} body - HTML content to display in modal body
 * @param {string} footer - HTML content to display in modal footer
 * @param {string} size - modal size (modal-lg, modal-sm)
 * @param {string} modalClass - modal additional class
 * @return {void}
 * 
 */
function modal(id, title, body, footer, size, modalClass) {
    var modal = document.getElementById(id);
    // Check if modal wrapper exist if not create new and append to the body
    if (!modal) {
        var wrapperTpl = document.createElement('div');
            wrapperTpl.classList.add('modal', 'fade');
            if (modalClass) {
                wrapperTpl.classList.add(modalClass);
            }
            wrapperTpl.setAttribute('id', id);
            wrapperTpl.setAttribute('tabindex', '-1');
            wrapperTpl.setAttribute('role', 'dialog');
        document.body.appendChild(wrapperTpl);
        modal = document.getElementById(id);
    }
    modal.innerHTML = '';
    // Prepare defulat template
    var dialogTpl = document.createElement('div');
        dialogTpl.classList.add('modal-dialog');
        dialogTpl.setAttribute('role', 'document');
    if (size) {
        dialogTpl.classList.add(size);
    }

    var contentTpl = document.createElement('div');
        contentTpl.classList.add('modal-content');

    var headerTpl = document.createElement('div');
        headerTpl.classList.add('modal-header');

    var buttonTpl = document.createElement('button');
        buttonTpl.classList.add('close');
        buttonTpl.setAttribute('type', 'button');
        buttonTpl.setAttribute('data-dismiss', 'modal');
        buttonTpl.setAttribute('aria-label', 'Close');

    var closeTpl = document.createElement('span');
        closeTpl.setAttribute('aria-hidden', 'true');
        closeTpl.innerHTML = '&times;';

    buttonTpl.appendChild(closeTpl);
    headerTpl.appendChild(buttonTpl);
    if (title) {
        var titleTpl = document.createElement('h4');
            titleTpl.classList.add('modal-title');
            titleTpl.innerHTML = title;
        headerTpl.appendChild(titleTpl);
    }
    contentTpl.appendChild(headerTpl);

    if (body) {
        var bodyTpl = document.createElement('div');
            bodyTpl.classList.add('modal-body');
            bodyTpl.innerHTML = body;
        contentTpl.appendChild(bodyTpl);
    }
    if (footer) {
        var footerTpl = document.createElement('div');
            footerTpl.classList.add('modal-footer');
        contentTpl.appendChild(footerTpl);
    }
    dialogTpl.appendChild(contentTpl);
    modal.appendChild(dialogTpl);
}

/**
 * Slide step from parent to target
 * 
 * @param {string} parent - parent step that we want to collapse
 * @param {string} target - target step that we want to show
 * @param {int} duration - animation time
 * @param {bool} scroll - indicator if we need to scroll to the target view
 * @returns {void}
 */
function slideStep(parent, target, duration, srcoll) {
    if (!duration) {
        duration = 500;
    }
    slideUp(parent, duration)
            .then(function (response) {
                slideDown(target, duration, srcoll);
            }, function (error) {
                slideDown(parent, duration, srcoll);
            });
}

/**
 * Slide element up
 * 
 * @param {string} target - target that we want to show
 * @param {int} duration - animation time
 * @returns {Promise}
 */
function slideUp(target, duration) {
    if (!duration) {
        duration = 500;
    }
    return new Promise(function (resolve, reject) {
        if (typeof target === 'undefined') {
            reject('element does not exist');
        } else {
            target.style.transitionProperty = 'height, margin, padding';
            target.style.transitionDuration = duration + 'ms';
            target.style.boxSizing = 'border-box';
            target.style.height = target.offsetHeight + 'px';
            target.style.overflow = 'hidden';
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            window.setTimeout(function () {
                target.style.display = 'none';
                target.style.removeProperty('height');
                target.style.removeProperty('padding-top');
                target.style.removeProperty('padding-bottom');
                target.style.removeProperty('margin-top');
                target.style.removeProperty('margin-bottom');
                target.style.removeProperty('overflow');
                target.style.removeProperty('transition-duration');
                target.style.removeProperty('transition-property');
                resolve('done');
            }, duration);
        }
    });

}

/**
 * Slide element down
 * 
 * @param {string} target - target that we want to collapse
 * @param {int} duration - animation time
 * @param {bool} scroll - indicator if we need to scroll to the target view
 * @returns {Promise}
 */
function slideDown(target, duration, scroll) {
    if (!duration) {
        duration = 500;
    }

    return new Promise(function (resolve, reject) {
        if (typeof target === 'undefined') {
            reject('element does not exist');
        } else {
            target.style.removeProperty('display');
            var display = window.getComputedStyle(target).display;
            if (display === 'none') {
                display = 'block';
            }
            target.style.display = display;
            var height = target.offsetHeight;
            target.style.overflow = 'hidden';
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            target.style.boxSizing = 'border-box';
            target.style.transitionProperty = "height, margin, padding";
            target.style.transitionDuration = duration + 'ms';
            target.style.height = height + 'px';
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            window.setTimeout(function () {
                target.style.removeProperty('height');
                target.style.removeProperty('overflow');
                target.style.removeProperty('transition-duration');
                target.style.removeProperty('transition-property');
                if (scroll) {
                    target.scrollIntoView({behavior: 'smooth'});
                }
                resolve('done');
            }, duration);
        }
    });
}

/**
 * Add Event, funtion to replicate jQuery document on event listener.
 * 
 * @param {object} parent - object that we want to listen
 * @param {string} evt - event type like 'click'
 * @param {string} selector - id, tag, class we want to start the handler function on event
 * @param {object} handler - function that we want to execute after event
 * @returns {void}
 */
function addEvent(parent, evt, selector, handler) {
    parent.addEventListener(evt, function (event) {
        if (event.target.matches(selector + ', ' + selector + ' *')) {
            handler.apply(event.target.closest(selector), arguments);
        }
    }, false);
}

// Polyfill fix for matches.
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

// Polyfill fix for closest.
if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var el = this;

        do {
            if (Element.prototype.matches.call(el, s))
                return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}
// Polyfill fix for trim.
if (!String.prototype.trim) {
    String.prototype.trim = function () {
        return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    };
}

/**
 * Helper function to initialize selectpicker.
 *
 * @param {string} elemId - optional id for selectpicker
 * @returns {void}
 */
function initSelectpicker(elemId) {
    /**
     * Floating picker, internal function helper.
     * 
     * @param {object} that -current processed node
     * @returns {void}
     */
    var floatingPicker = function (that) {
        var id = $(that).attr('id');
        if (id === 'delivery_time') {
            return;
        }
        var formGroup = $(that).parent().parent();
        if (that.value) {
            if (formGroup.hasClass('empty')) {
                formGroup.removeClass('empty');
            }
        } else {
            if (!formGroup.hasClass('empty')) {
                formGroup.addClass('empty');
            }
        }
    };
    if(!elemId) {
        elemId = '.selectpicker';
    }
    if ($(elemId).length) {
        $(elemId).selectpicker();
        $(elemId).on('loaded.bs.select', function () {
            $('.bootstrap-select').removeClass('chk-select');
        });
        if ($(window).width() >= 768) {
            $(elemId).on('loaded.bs.select', function () {
                var parent = $(this).parent();
                var select = $(this).closest('select');
                parent.attr('data-original-title', select.attr('data-original-title'));
                parent.attr('data-content', select.attr('data-content'));
                initPopover();
            });
            $(elemId).on('show.bs.select', function () {
                $(this).parent().popover('show');
            });
            $(elemId).on('hide.bs.select', function () {
                $(this).parent().popover('hide');
            });
        }
        $(elemId).on('change', function () {
            floatingPicker(this);
        });
        $(elemId).on('refreshed.bs.select', function () {
            floatingPicker(this);
        });
    }
}

/**
 * Helper function to initialize BS popover.
 * 
 * @returns {void}
 */
function initPopover()
{
    $('[data-toggle="popover"]').popover({
        trigger: 'focus',
        html: true,
        placement: function () {
            return $(window).width() < 768 ? 'top' : 'right';
        }
    });
    $('.bootstrap-select').popover({
        trigger: 'manual',
        html: true,
        placement: function () {
            return $(window).width() < 768 ? 'top' : 'right';
        }
    });

}

/**
 * Infinity load button
 *
 * @param {object} e - event handler
 * @returns {void}
 */
function infinityLoadMore(e)
{
    e.preventDefault();
    var that = this;
    var hiddeButton = function () {
        that.classList.add('hidden');
    };
    var toLoad = that.getAttribute('data-load');
    var target = that.getAttribute('data-target');
    var elem = document.querySelector(target);
    if (!elem) {
        return hiddeButton();
    }
    var hidden = elem.querySelectorAll('.d-none');
    if (!hidden.length) {
        return hiddeButton();
    }
    var i = 1;
    hidden.forEach(function (item) {
        if (i <= toLoad || toLoad === 'all') {
            slideDown(item, 200);
            item.classList.remove('d-none');
        }
        i++;
    });
    if (toLoad === 'all') {
        return hiddeButton();
    }
}


/**
 * @param object date Javascript date object
 * @returns {string}
 */
function dateToYMD(date) {
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

/**
 * @param string date yyyy-mm-dd format
 * @returns {string} m/d/Y
 */
function ymdToMdy(date) {
    var parts = date.split('-');
    return '' + parseInt(parts[1]) + '/' + parseInt(parts[2]) + '/' + parts[0];
}

/**
 * @param string date yyyy-mm-dd format
 * @returns {string} m/d
 */
function ymdToMd(date) {
    var parts = date.split('-');
    return '' + parseInt(parts[1]) + '/' + parseInt(parts[2]);
}

/** 
 * Get JSON data from HTML data tag attribute.
 * 
 * @param {string} id - The ID of the HTML tag
 * @param {string} attrData - The data- attribute tag
 * @returns {Array|Object} - parsed data
 */
function getJsonData(id, attrData)
{
    var data = $(id).attr(attrData);
    try {
        if (data && isJSON(data)) {
            data = JSON.parse(data);
        }
    } catch (e) {
        if (e instanceof SyntaxError) {
            logError(e, true);
        } else {
            logError(e, false);
        }
    }
    return data;
}

/**
 * Parse string date to Y-m-d from selected date.
 * 
 * @param {String} date - jQuery selected date
 * @returns {String} - paresed Y-m-d date
 */
function parseDateToYmd(date) {

    var regexDate = /(\d{1,2}-\d{1,2}-\d{4})/gm;
    var res = date.match(regexDate);
    if (res) {
        var dateParts = date.split('-');
        date = dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1];
    }
    return date;
}

/**
 * Wrapper for fbq function.
 *
 * @param {String} event - event name
 * @param {Object} data - data passed to FB
 * @param {Object} additionalData - additional data passed to FB e.g. eventID
 *
 * @returns {Voids}
 */
function fbTrack(event, data, additionalData)
{
    if (typeof fbq === 'undefined') {
        return;
    }
    if (additionalData) {
        fbq('track', event, data, additionalData);
        return;
    }
    fbq('track', event, data);
}

/**
 * Send custom gtag event.
 *
 * @param {String} event - name of the event
 * @param {Object} data - event data
 * @param {String} sendTo - the tag ID
 *
 * @returns {Void}
 */
function gTagEvent(event, data, sendTo)
{
    if (typeof gtag === 'undefined') {
        return;
    }
    if (!data.send_to || sendTo) {
        data.send_to = 'AW-980990295';
    }
    gtag('event', event, data);
}