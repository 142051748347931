/**
 * CustomerPrivacyApp created to handle privacy related functionality.
 */

// only the init if the customer privacy page is being displayed.
if(WebPageObj.customerPrivacyRequestVerification) {
    $(document).ready(function(){
        CustomerPrivacyRequestVerificationApp.init();
    });
}

/**
 * Obj to handle the app code.
 *
 * @returns obj
 *
 * @constructor
 */
window.CustomerPrivacyRequestVerificationApp = function(){

    var verificationFeedbackPanel = $(".hiddenFeedbackPanel");
    var sendVerificationRequestBtn = $("#sendVerificationRequestBtn");

    var generalFailureError = "An unexpected error has occurred. Please try again or submit an inquiry to privacy@avasflowers.com, if the problem persists.";

    return {

        /**
         * Called to init the object
         *
         * @return void
         */
        init: function () {
            var that = this;

            // when the verify request button is clicked on
            sendVerificationRequestBtn.on("click", function (e) {
                e.preventDefault();
                that.handleRequestForm();
            });
        },

        /**
         * handle the request verification form when it's submitted
         *
         * @return void
         */
        handleRequestForm: function () {

            var that = this;

            var originalBtnText = sendVerificationRequestBtn.val();
            var waitMsg = "Please Wait...";

            // do not proceed, an existing call is being processed
            if (sendVerificationRequestBtn.val() == waitMsg) {
                return;
            }

            // update submit button
            sendVerificationRequestBtn.val(waitMsg);

            // hide the feedback panel
            hideFeedbackPanel(verificationFeedbackPanel);

            var firstNameInput = $("#firstName");
            var lastNameInput = $("#lastName");
            var emailInput = $("#customerEmail");

            $.ajax({
                url: "/api/cs_privacy/send_request_verification",
                method: "POST",
                data: {
                    firstName: firstNameInput.val(),
                    lastName: lastNameInput.val(),
                    email: emailInput.val()
                },
                dataType: "json",
                success: function (data, textStatus, jqXHR) {

                    if (data.status) {

                        firstNameInput.val("");
                        lastNameInput.val("");
                        emailInput.val("");

                        that.displayVerificationFeedbackPanel('success', data.message);
                    } else {
                        if (data.showInfoBox) {
                            that.displayVerificationFeedbackPanel('info', data.message);
                        } else {
                            that.displayVerificationFeedbackPanel('error', data.message);
                        }
                    }

                    // restore button text
                    sendVerificationRequestBtn.val(originalBtnText);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    that.displayVerificationFeedbackPanel('error', generalFailureError);

                    // restore button text
                    sendVerificationRequestBtn.val(originalBtnText);
                }
            });
        },

        /**
         * Display the feedback panel
         *
         * @param string type - The type of the feedback to display: error, success, info
         * @param string message - The error message to display
         *
         * @return void
         */
        displayVerificationFeedbackPanel: function (type, message) {

            var that = this;

            // panel settings
            var panelSettings = {
                default: {panelClass: 'panel-default', panelHeading: "See below message."},
                error: {panelClass: 'panel-danger', panelHeading: "Error!"},
                info: {panelClass: 'panel-info', panelHeading: "Info!"},
                success: {
                    panelClass: 'panel-success',
                    panelHeading: "Please follow the instructions below to proceed."
                },
            };

            displayFeedbackPanel(panelSettings, verificationFeedbackPanel, type, message);
        },

    };
}();