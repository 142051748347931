/* global FooterLinks */
// original file system/application/views/layout/pages_foot.php
$(function () {
    /**
     * Handle Footer Link Click.
     *
     * @param {Node}   el   - the li element
     * @param {String} path - the URL
     *
     * @returns {Void}
     */
    function handleFooterLinkClick(el, path) {
        $(el).on("click", function () {
            renderFooterLinks(path);
        });
    }

    /**
     * Function to render footer links
     *
     * @param current_url
     *
     * @return void
     */
    function renderFooterLinks(current_url) {
        var links;
        var link;
        var section;
        $('#footerLinksSite').html("");

        for (var footerLinkSectionData in FooterLinks.sections) {
            links = document.createElement("ul");

            for (var footerLinkData in FooterLinks.links[footerLinkSectionData]) {
                link = document.createElement("li");

                var fl = FooterLinks.links[footerLinkSectionData][footerLinkData];

                if (fl.is_external_link == "1") {
                    link.innerHTML = "<a href=\"" + fl.path + "\" title=\"" + fl.identifier + "\">" + fl.identifier + "</a>";
                } else {
                    if (fl.path == current_url && fl.apply_active_page_mode == "1") {
                        link.className = "active";
                        link.innerHTML = (fl.alternate_active_link_text ? fl.alternate_active_link_text : fl.identifier);
                    } else {
                        link.innerHTML = "<a href=\"" + fl.path + "\">" + fl.identifier + "</a>";
                    }
                }
                handleFooterLinkClick(link, fl.path);
                links.appendChild(link);
            }

            section = document.createElement("div");
            section.className = "footLinkBox footLinkBox4Col";
            section.innerHTML = "<h3>" + FooterLinks.sections[footerLinkSectionData] + "</h3>";
            section.appendChild(links);

            $('#footerLinksSite').append(section);
        }
    }
    if (typeof FooterLinks !== 'undefined' && FooterLinks.sections && FooterLinks.links) {
        renderFooterLinks(window.location.href.replace(/\/+$/, ""));
    }

    var siteSeal = document.createElement("script");
    siteSeal.type = "text/javascript";
    siteSeal.async = true;
    siteSeal.src = "https://seal.godaddy.com/getSeal?sealID=VGglttpS1oajCnQPaVDVppV4fRDlTXF8WOVi8uiIKFrK2RamYHynszsefZaw";

    var siteSealSpan = document.getElementById('siteseal');
    if (siteSealSpan) {
        siteSealSpan.appendChild(siteSeal);
    }

});

