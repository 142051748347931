/**
 * CustomerPrivacyApp created to handle privacy related functionality.
 */

// only the init if the customer privacy page is being displayed.
if(WebPageObj.customerPrivacyRequestConfirmation) {
    $(document).ready(function(){
        CustomerPrivacyRequestConfirmationApp.init();
    });
}

/**
 * Obj to handle the app code.
 *
 * @returns obj
 *
 * @constructor
 */
window.CustomerPrivacyRequestConfirmationApp = function(){

    var confirmationFeedbackPanel = $(".hiddenFeedbackPanel");
    var confirmFormContainer = $("#confirmCPLinkFormContainer");
    var confirmRequestBtn = $("#confirmRequestBtn");

    var generalFailureError = "An unexpected error has occurred. Please try again or submit an inquiry to privacy@avasflowers.com, if the problem persists.";

    return {

        /**
         * Called to init the object
         *
         * @return void
         */
        init: function () {
            var that = this;

            // when the confirm request button is clicked on
            that.checkIfEmailVerificationLinkIsValid(function(){
                confirmRequestBtn.on("click", function (e) {
                    e.preventDefault();
                    that.handleRequestConfirmationForm();
                });
            });
        },

        /**
         * On page load, check if the confirmation is valid
         *
         * @param function callback - To be executed, if the link is active
         *
         * @return void
         */
        checkIfEmailVerificationLinkIsValid: function(callback){

            var that = this;
            var requestIdentifierInput = $("#requestIdentifier");
            var pageWaitIndicationContainer = $(".page-wait-indication-container");

            $.ajax({
                url: "/api/cs_privacy/check_email_verification_link_validity",
                method: "POST",
                data: {
                    requestIdentifier: requestIdentifierInput.val()
                },
                dataType: "json",
                success: function (data, textStatus, jqXHR) {
                    pageWaitIndicationContainer.remove();
                    if (data.status) {
                        confirmFormContainer.fadeIn();
                        if(callback){
                            callback();
                        }
                    } else {
                        confirmFormContainer.remove();
                        if (data.showInfoBox) {
                            that.displayConfirmationFeedbackPanel('info', data.message);
                        } else {
                            that.displayConfirmationFeedbackPanel('error', data.message);
                        }
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    // do nothing
                }
            });

        },

        /**
         * When the confirm email button is clicked on.
         *
         * @return void
         */
        handleRequestConfirmationForm: function () {

            var that = this;

            var waitMsg = "Please Wait...";
            var originalBtnTxt = confirmRequestBtn.val();

            // do not continue, if an existing request is being processed
            if (confirmRequestBtn.val() == waitMsg) {
                return;
            }

            // update button to display the wait message
            confirmRequestBtn.val(waitMsg);

            // get form data
            var requestIdentifierInput = $("#requestIdentifier");

            // hide the feedback panel
            hideFeedbackPanel(confirmationFeedbackPanel);

            $.ajax({
                url: "/api/cs_privacy/confirm_request_verification",
                method: "POST",
                data: {
                    requestIdentifier: requestIdentifierInput.val()
                },
                dataType: "json",
                success: function (data, textStatus, jqXHR) {
                    if (data.status) {
                        confirmFormContainer.slideUp();
                        that.displayConfirmationFeedbackPanel('success', data.message);
                    } else {
                        if (data.showInfoBox) {
                            that.displayConfirmationFeedbackPanel('info', data.message);
                        } else {
                            that.displayConfirmationFeedbackPanel('error', data.message);
                        }
                    }

                    // restore button text
                    confirmRequestBtn.val(originalBtnTxt);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    that.displayConfirmationFeedbackPanel('error', generalFailureError);

                    // restore button text
                    confirmRequestBtn.val(originalBtnTxt);
                }
            });
        },

        /**
         * Display the feedback panel
         *
         * @param string type - The type of the feedback to display: error, success, info
         * @param string message - The error message to display
         *
         * @return void
         */
        displayConfirmationFeedbackPanel: function (type, message) {

            var that = this;

            displayFeedbackPanel(null, confirmationFeedbackPanel, type, message);
        },

    };
}();